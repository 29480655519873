import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// ** Authentication init
import { AuthInit } from './contexts/AuthContext';

import routes, { renderRoutes } from './routes';
const baseUrl = process.env.PUBLIC_URL;

const App = () => {
  return (
    <React.Fragment>
      <Router basename={baseUrl}>
        <AuthInit>
          {renderRoutes(routes)}
        </AuthInit>
      </Router>
    </React.Fragment>
  );
};

export default App;
