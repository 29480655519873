import React, { Suspense, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import AdminLayout from './layouts';
import { useAuth } from './contexts/AuthContext';
const Login = lazy(() => import('./views/auth/signin/SignIn'));
const Dashboard = lazy(() => import('./views/dashboard'));
const Transactions = lazy(() => import('./views/transactions/Transactions'));
const Settings = lazy(() => import('./views/settings'));

const PublicRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useAuth().auth;
  return (
    <Route
      {...rest}
        render={(props) => {
          if (!isAuthenticated) {
            return <Component {...rest} {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: {
                    from: props.location,
                  }
                }}
              />
            );
          }
        }}
    />
  );
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useAuth().auth;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <AdminLayout> <Component {...rest} {...props} /></AdminLayout>;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: {
                  from: props.location,
                }
              }}
            />
          );
        }
      }}
    />
  );
};

export const renderRoutes = () => (
  <Suspense fallback={<Loader />}>
    <Switch>
      <PublicRoute path="/signin" exact component={Login} />
      <ProtectedRoute path="/dashboard" exact component={Dashboard} />
      <ProtectedRoute path="/transactions" exact component={Transactions} />
      <ProtectedRoute path="/settings" component={Settings} />
      <Redirect to="/signin" />
    </Switch>
  </Suspense>
);

export default renderRoutes;
