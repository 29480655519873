import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthContext';

import useWindowSize from '../../../hooks/useWindowSize';
// import { ConfigContext } from '../../../contexts/ConfigContext';
import NavSearch from './NavSearch';

const NavLeft = () => {
  const windowSize = useWindowSize();
  const { currentUser } = useAuth();

  // const configContext = useContext(ConfigContext);
  // const { rtlLayout } = configContext.state;
  // let dropdownRightAlign = false;
  // if (rtlLayout) {
  //   dropdownRightAlign = true;
  // }

  let navItemClass = ['nav-item'];
  if (windowSize.width <= 575) {
    navItemClass = [...navItemClass, 'd-none'];
  }

  return (
    <React.Fragment>
      <ListGroup as="ul" bsPrefix=" " className="navbar-nav mr-auto">
        <ListGroup.Item as="li" bsPrefix=" " className={navItemClass.join(' ')}>
          <span>Welcome back, {currentUser && currentUser.name}</span>
        </ListGroup.Item>
        <ListGroup.Item as="li" bsPrefix=" " className="nav-item">
          <NavSearch windowWidth={windowSize.width} />
        </ListGroup.Item>
      </ListGroup>
    </React.Fragment>
  );
};

export default NavLeft;
