const menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/dashboard',
          icon: 'feather icon-home',
        },
        {
          id: 'transactions',
          title: 'Transactions',
          type: 'item',
          url: '/transactions',
          icon: 'feather icon-menu',
        },
        {
          id: 'settings',
          title: 'Settings',
          type: 'item',
          url: '/settings',
          classes: 'nav-item',
          icon: 'feather icon-settings'
        }
      ]
    }
  ]
};

export default menuItems;
