import axios from 'axios';
import { getAuth } from './Utils';

// ** Create axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASENAME
});

// ** Request Interceptor
axiosInstance.interceptors.request.use(request => {
    // ** Get token from localStorage
    const userData = getAuth();

    // ** Add request's Headers
    if (userData) {
      // ** eslint-disable-next-line no-param-reassign
      request.headers['Authorization'] = 'Bearer ' + userData.token;
      request.headers['Content-Type'] = 'application/json';
      request.headers['Accept'] = 'application/json';
    }
    return request;
  },
  error => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosInstance;

