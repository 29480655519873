// ** React Imports
import { useRef, useState, useEffect, createContext, useContext } from 'react';

// ** AxiosInstance Imports
import axiosService from '../utils/axios';

// ** Utils Imports 
import * as authHelper from '../utils/Utils';

// ** Custom Components
import LayoutSplashScreen from '../components/Loader/Loader';

// ** Create Context
const AuthContext = createContext();

// ** UseAuth Context
const useAuth = () => {
  return useContext(AuthContext);
}

// ** AuthProvider Context
const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState(undefined);

  const handleLogin = (authData) => {
    setAuth(authData);
    if (authData) {
      authHelper.setAuth(authData);
    } else {
      authHelper.removeAuth();
    }
  }

  const handleLogout = () => {
    handleLogin(undefined);
    setCurrentUser(undefined);
  }

  return (
    <AuthContext.Provider value={{auth, handleLogin, currentUser, setCurrentUser, handleLogout}}>
      {children}
    </AuthContext.Provider>
  )
}

// ** AuthInit Context
const AuthInit = ({children}) => {
  const {auth, handleLogout, setCurrentUser} = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const userData = await axiosService.get('/api/profile');
          if (userData) {
            setCurrentUser(userData.data.user);
          }
        }
      } catch (error) { 
        if (!didRequest.current) {
          handleLogout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true)
    }
    
    if (auth !== undefined && auth.token) {
      requestUser();
    } else {
      handleLogout();
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, [auth && auth.token])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}